import React, { useState, useEffect } from "react";
import { ReactComponent as Calendar } from './Calendar.svg';
import { ReactComponent as Location } from './Location.svg';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import { informationWithPastEvents, loggedInUser, loggedInEmail, isAdmin, formattedDate, formattedTime } from './information';
import addresses from './information';
import Footer from './Footer';
//const information = require("./information.js");
import Attendees from "./Attendees";
import { ReactComponent as Share } from "./Share.svg";
import { ReactComponent as IconUmmahfy } from "./IconUmmahfy.svg";
import { ReactComponent as IconUmmahfyTwo } from "./IconUmmahfyTwo.svg";
import { ReactComponent as IconUmmahfyThree } from "./IconUmmahfyThree.svg";
import axios from "axios";
import ShareButton from "./ShareButton.js";
import RegisterModal from "./RegisterModal.js";
import EditRSVP from "./EditRSVP.js";
import EditEvent from "./EditEvent.js";
import SignUp from "./SignUp.js";
import Ummahfy from './Ummahfy.png';
import { ReactComponent as Avatar } from "./Avatar.svg";
import { Helmet } from "react-helmet";





const EventShell = () => {

  const secrets = require("./secrets.js");
  const api = secrets.server_url;

  const { id } = useParams();
  ////console.log(id)
  const info = informationWithPastEvents.find((item) => item._id === id);
  //console.log(info)

  const [registerModal, setRegisterModal] = useState(false);
  const [isRegistered, setIsRegistered] = useState(info.registered.includes(loggedInEmail.data));
  const [editRsvp, setEditRsvp] = useState(false);
  const [editEvent, setEditEvent] = useState(false);
  const [user, setUser] = useState(loggedInUser.data);
  const [email, setEmail] = useState(loggedInEmail.data);
  const [buttonSignUp, setButtonSignUp] = useState(false);

  const formattedDescription = info.description.replace(/\\n/g, "\n");


  window.scrollTo(0, 0);

  function GoogleMap({ address }) {
    const apiKey = secrets.google_maps_api_key;


    return (
      <>


        {address ? (
          <a className="es-map" href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`} target="_blank" rel="noopener noreferrer">
            <iframe
              title="Google Map"
              className="event-shell-map"
              frameBorder="0" // Remove the default border
              allowFullScreen
              loading="eager"
              src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(address)}&zoom=15&key=${apiKey}`}
            ></iframe>
          </a>
        ) : (
          <div class="map-error-mask">

          </div>
        )}
      </>
    );
  }

  async function getUserDetails(email) {
    try {
      const response = await axios.get(`${api}users/${email}/`, {
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
      });
      //console.log("User details:", response.data.data[0].email);
      return response.data.data[0];
    } catch (error) {
      console.error(error);
      throw error; // Handle or rethrow error
    }
  }



  const updateUserDetails = async (email, updates) => {

    // //console.log(updates);
    try {
      const response = await axios.put(`${api}users/${email}/`, updates, {
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
      });
      return response.data.data;
    } catch (error) {
      console.error("Error updating user details:", error);
      throw error;
    }
  };

  // useEffect(() => {
  //   const user= fetchSessionData();
  //   setIsRegistered(info.registered.includes(user.data))
  //   //console.log(setIsRegistered, "is this registered or no")
  // })


  //   /* -------------- this checks if user is registered -------------- */
  //   useEffect(() => {
  //     const fetchData = async () => {
  //       const user = await fetchSessionData(); // Make sure fetchSessionData() returns a Promise
  //       setIsRegistered(info.registered.includes(user.data));
  //     };

  //     fetchData();
  //   }, []);

  //   /* -------------- this checks if user is signed in  -------------- */
  //   useEffect(() => {
  //     const fetchUser = async () => {
  //         const user1 = await fetchSessionData();
  //         setUser(user1.data);
  //     };

  //     fetchUser();
  // }, []);



  async function handleRegister(id) {
    // const user = await fetchSessionData();
    //console.log("calling handle register")
    //console.log(user)

    if (!user) {
      // if no user is logged in
      //console.log("ayo u gotta log in bruh");
      // //console.log(user)
      alert("you need to sign in")
      return;
    }
    //console.log("user is logged in", user);
    var info = await informationWithPastEvents.find((item) => item._id === id);
    if (!info) {
      //console.log("Event not found");
      return;
    }


    if (info.registered.includes(email)) {
      //console.log("User is already registered for this event.");

      return;
    }

    info.registered.push(email);
    //console.log("Updated event info with new registration:", info);

    try {
      const response = await axios.put(`${api}events/${info.event_id}`, info, {
        withCredentials: true,
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
      });

      // Fetch the updated user details

      const userDetails = await getUserDetails(email);

      userDetails.signed_up_events.push(info.event_id);
      // Update the user's details in the database, with req.body which is signed_up_events
      //console.log("NOWW", userDetails.signed_up_events);
      const updatedUserDetails = await updateUserDetails(email, { signed_up_events: userDetails.signed_up_events });
      //console.log("YOOO", updatedUserDetails); //! not showing up here


      //send confirmation email

      //send confirmation email
      if (loggedInEmail.data) {
        console.log("sending conf email")
        const payload = {
          email: loggedInEmail.data,
          eventName: info.name,
          eventURL: window.location.href,
          ticket: "",                        //ticketURL
          type: "Register for Event"
        };

        try {
          const response = await axios.post(`${api}sendEmail`, payload, {
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
            }
          });
          console.log('Response:', response.data);
        } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
        }
      }


      // if(loggedInEmail.data){
      //   const payload = {
      //     email: loggedInEmail.data,
      //     eventName: info.name
      //   };

      //   try {
      //       const response = await axios.post(`${api}sendEmail`, payload, {
      //           headers: {
      //               'Content-Type': 'application/json'
      // 'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
      //           }
      //       });
      //       //console.log('Response:', response.data);
      //   } catch (error) {
      //       console.error('Error:', error.response ? error.response.data : error.message);
      //   }
      // }

    } catch (error) {
      console.error("Error updating event or user details:", error);
    }
  }


  async function handleUnRegister(id) {
    //console.log("calling handle UNregister");
    //console.log(user)
    // const user = await fetchSessionData();
    // //console.log("UUSISSRE", user);

    if (!user) {
      // if no user is logged in
      //console.log("ayo u gotta log in bruh");
      //console.log(user)
      return;
    }
    //console.log("user is logged in", user);
    var info = await informationWithPastEvents.find((item) => item._id === id);
    if (!info) {
      //console.log("Event not found");
      return;
    }

    // alert("You registered for the event!");
    // //console.log("Event info:", info);
    //! why are we calling user which is the username? js call it user.username

    if (!info.registered.includes(email)) {
      //console.log("User is not registered for this event.");
      return;
    }

    // info.registered.push(user);

    let index = info.registered.indexOf(email);
    if (index !== -1) {
      info.registered.splice(index, 1);
    }
    //console.log("Updated event info with new registration:", info);

    try {
      const response = await axios.put(`${api}events/${info.event_id}`, info, {
        withCredentials: true,
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
      });

      // Fetch the updated user details

      const userDetails = await getUserDetails(email);

      //console.log(userDetails)
      // userDetails.signed_up_events.push(info.name);
      let index = userDetails.signed_up_events.indexOf(info.event_id.toString());
      //console.log(index)
      if (index !== -1) {
        await userDetails.signed_up_events.splice(index, 1);
      }
      // Update the user's details in the database, with req.body which is signed_up_events
      //console.log("NOWW", userDetails.signed_up_events);
      const updatedUserDetails = await updateUserDetails(email, { signed_up_events: userDetails.signed_up_events });
      //console.log("YOOO", updatedUserDetails); //! not showing up here
    } catch (error) {
      console.error("Error updating event or user details:", error);
    }
  }



  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  function formatEventTime() {
    //console.log(info)
    const start = new Date(info.start_datetime);
    const end = new Date(info.end_datetime);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      console.error("Invalid date or time format");
      return { formattedDate: "Invalid date or time format", formattedTime: "" };
    }

    // Format the dates (e.g., "Mon, Jun 17" and "Thu, Jul 25")
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    });

    const startDateFormatted = dateFormatter.format(start);
    const endDateFormatted = dateFormatter.format(end);

    // Format the times (e.g., "8:30am" and "1:20pm")
    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });

    // Format start time and remove ':00' if present
    let startTimeFormatted = timeFormatter.format(start).replace(/ /g, '').replace('AM', 'am').replace('PM', 'pm');
    if (startTimeFormatted.endsWith(':00am') || startTimeFormatted.endsWith(':00pm')) {
      startTimeFormatted = startTimeFormatted.replace(':00', '');
    }

    // Format end time and remove ':00' if present
    let endTimeFormatted = timeFormatter.format(end).replace(/ /g, '').replace('AM', 'am').replace('PM', 'pm');
    if (endTimeFormatted.endsWith(':00am') || endTimeFormatted.endsWith(':00pm')) {
      endTimeFormatted = endTimeFormatted.replace(':00', '');
    }

    // Determine if the event spans multiple days
    let formattedDate;
    if (startDateFormatted === endDateFormatted) {
      // Same day
      formattedDate = `${startDateFormatted}`;
    } else {
      // Different days
      formattedDate = `${startDateFormatted} - ${endDateFormatted}`;
    }

    const formattedTime = `${startTimeFormatted} - ${endTimeFormatted}`;

    return { formattedDate, formattedTime };
  }

  const { formattedDate, formattedTime } = formatEventTime();

  const formatPrice = (price) => {
    console.log("check if price is 0 or free", price)

    // Check if price is 0 or "Free" and return "Free" directly
    if (price === "0" || price === "Free") {
      return "Free";
    }

    // Convert price to string if it's a number
    let priceStr = price.toString();

    // Add '$' if it's not already present
    if (!priceStr.startsWith("$")) {
      priceStr = `$${priceStr}`;
    }

    return priceStr;
  };



  // Function to dynamically generate Open Graph meta tags
  const getMetaTags = () => {
    return (
      <Helmet>
        <title>{info.name}</title>
        <meta property="og:title" content={info.name} />
        <meta property="og:description" content={info.description} />
        <meta property="og:url" content={`https://www.ummahfy.com/event/${info._id}`} />
        <meta property="og:image" content={info.img} />
      </Helmet>
    );
  };


  return (
    <>

      {getMetaTags()}

      <div class="wrapper event-shell-wrapper">
        <Navbar> </Navbar>

        <div class="event-shell-container">
          <div class="flyer">
            <img class="imgStyle" src={info.img} />
          </div>
          <div class="title-register">
            <p class="event-shell-name"> {info.name} </p>
            <div class="avatar-org">
              {/* <Avatar className="avatar-icon" /> */}
              <svg class="avatar-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zM6.023 15.416C7.491 17.606 9.695 19 12.16 19c2.464 0 4.669-1.393 6.136-3.584A8.968 8.968 0 0 0 12.16 13a8.968 8.968 0 0 0-6.137 2.416zM12 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </g>
              </svg>
              <p class="event-shell-org"> {info.organization} </p>
            </div>

            {/* {info.past && <>
              <button className="already-registered-btn passed-button"
              > This Event has Passed. </button>
            </>} */}


            {/* {user ? (
              <>
                {isRegistered ? (
                  <>
                    <p class="registered-text"> You’re Going to this Event!</p>
                    <button className="already-registered-btn"
                      onClick={() => {
                        setEditRsvp(true)

                        // id={id} // Pass the id as a prop
                      }}
                    > Edit RSVP </button>

                    <EditRSVP id={id} handleUnRegister={handleUnRegister} trigger={editRsvp} setTrigger={setEditRsvp} />

                  </>
                ) : (
                  <>
                    <button
                      className="register-btn"
                      onClick={() => {
                        handleRegister(id)
                        setRegisterModal(true)
                      }}> Register for this Event </button>
                    <RegisterModal trigger={registerModal} setTrigger={setRegisterModal} info={info} />
                  </>
                )}
              </>
            ) : (
              <>
                <button
                  className="register-btn"
                  onClick={() => {
                    setButtonSignUp(true)
                  }}
                > Register for this Event</button>
                <SignUp trigger={buttonSignUp} setTrigger={setButtonSignUp} />
              </>
            )} */}

            {info.past ? (
              <>
                <button className="already-registered-btn passed-button">
                  This Event has Passed.
                </button>
              </>
            ) : (
              <>
                {user ? (
                  <>
                    {isRegistered ? (
                      <>
                        <p className="registered-text">You’re Going to this Event!</p>
                        <button
                          className="already-registered-btn"
                          onClick={() => {
                            setEditRsvp(true);
                          }}
                        >
                          Edit RSVP
                        </button>
                        <EditRSVP
                          id={id}
                          handleUnRegister={handleUnRegister}
                          trigger={editRsvp}
                          setTrigger={setEditRsvp}
                        />
                      </>
                    ) : (
                      <>
                        <button
                          className="register-btn"
                          onClick={() => {
                            handleRegister(id);
                            setRegisterModal(true);
                          }}
                        >
                          Register for this Event
                        </button>
                        <RegisterModal
                          trigger={registerModal}
                          setTrigger={setRegisterModal}
                          info={info}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <button
                      className="register-btn"
                      onClick={() => {
                        setButtonSignUp(true);
                      }}
                    >
                      Register for this Event
                    </button>
                    <SignUp trigger={buttonSignUp} setTrigger={setButtonSignUp} />
                  </>
                )}
              </>
            )}

          </div>

          <div class="event-shell-info">
            <div class="event-shell-date">
              <Calendar className="calendar-icon" />
              <p class="event-shell-datetime">{formattedDate}&thinsp; ·&thinsp;{formattedTime}</p>
            </div>

            <div class="event-shell-location">

              {/* add address here using function */}
              {info.location ? (
                <a className="es-location-link" href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(info.location)}`} target="_blank" rel="noopener noreferrer">
                  <Location className="location-icon" />
                  <p>{info.location}</p>
                </a>
              ) : (
                <div className="no-location">
                  <Location className="location-icon" />
                  <p>No location given, check organizer's site.</p>
                </div>
              )}

            </div>

            <div class="event-shell-description">
              <p> {formattedDescription}</p>
            </div>

            <div class="badge-grp bdgrp-evsh" style={{ display: 'flex', flexWrap: 'wrap' }}>

              {info.frequency && info.frequency !== 'once' && (
                <div className="badge time-badge">
                  <p>{capitalizeFirstLetter(info.frequency)}</p>
                </div>
              )}

              {info.age_range && info.age_range !== 'all' && (
                <div className="badge age-badge">
                  <p>{capitalizeFirstLetter(info.age_range)}</p>
                </div>
              )}

              {info.gender && info.gender !== ('all' || '') && (
                <div className="badge gender-badge">
                  <p>{capitalizeFirstLetter(info.gender)}</p>
                </div>
              )}
              <div class="badge price-badge"  >  <p>{formatPrice(info.price)}</p> </div>
            </div>
          </div>
          <div class="attendees-share">
            <div class="attendees">
              <button>
                <IconUmmahfy style={{ width: '16px', marginRight: '-6px', }} />
                <IconUmmahfyTwo style={{ width: '16px', marginRight: '-6px', fill: '#fff' }} />
                <IconUmmahfyThree style={{ width: '16px', marginRight: '6px', }} />
                {info.registered.length} going
              </button>
            </div>
            <div class="share">
              <ShareButton buttontext="Copy Link" styling="es-share-button" iconstyling="share-button-icon" />

              {/* <button class="es-share-button">
                    Share
                    <div class="es-share-blue"> </div>
                  </button> */}
            </div>
          </div>
          <div class="rect5">
            <GoogleMap address={info.location} />
          </div>
        </div>
      </div>

      {user ? (
        <>
          {isAdmin ? (
            <>
              <button className="already-registered-btn"
                onClick={() => {
                  setEditEvent(true)

                  // id={id} // Pass the id as a prop
                }}
              > Edit Event Details </button>

              <EditEvent info={info} trigger={editEvent} setTrigger={setEditEvent} />

            </>
          ) : (
            <>

            </>
          )}
        </>
      ) : (
        <>

        </>
      )}

      <Footer> </Footer>
    </>
  );



};

export default EventShell;


